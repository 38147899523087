import { useGLPagination, useI18n } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";

import { useFlags } from "../../../Context/FlagsProvider";
import { useReportListService } from "../../../Services/reportListService/useReportListService";

export const useReportsContent = () => {
  const { getReports, getReportsAvailables } = useReportListService();
  const { state: paginationState } = useGLPagination();
  const { hasPrivileges, hasBetaPrivileges } = useFlags();

  const { t } = useI18n();

  const {
    data: reports,
    isLoading: reportsIsLoading,
    refetch: reportsRefetch,
  } = useQuery({
    queryKey: ["get-reports"],
    queryFn: async () => {
      const response = await getReports({
        name: paginationState.search,
      });

      return response;
    },
  });

  const { data: reportsAvailables, isLoading: isLoadingReportsAvailables } =
    useQuery({
      queryKey: ["reports-availables"],
      queryFn: async () => {
        const response = await getReportsAvailables();

        // TODO: por enquanto apenas o srFullExport é gerado relatório

        return response
          .filter((report) => {
            if (report.type === "NotificationListReport") {
              return hasBetaPrivileges("beta");
            }
            return true;
          })
          .map((report) => ({
            ...report,
            name:
              report.type === "SrFullExport" ||
              report.type === "NotificationListReport"
                ? t(
                    `reports.modalStepper.stepper.one.form.reportType.${report.type}.name`
                  )
                : report.name,
          }));
      },
    });

  return {
    reports,
    reportsIsLoading,
    reportsRefetch,
    hasPrivileges,
    reportsAvailables,
    isLoadingReportsAvailables,
    t,
  };
};
