import { useAuth } from "@group-link-one/gl-auth";
import {
  GLPageNotFound,
  useGLModalLogout,
} from "@group-link-one/grouplink-components";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glsmartretail-dark.svg";
import GLLogo from "../images/Common/logo-glsmartretail-light.svg";
import { DashboardLayout } from "../layouts/DashboardLayout/DashboardLayout";
import { DevelopersLayout } from "../layouts/DevelopersLayout/DevelopersLayout";
import { EventListLayout } from "../layouts/EventListLayout/EventListLayout";
import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { MessageCenterLayout } from "../layouts/MessageCenterLayout/MessageCenterLayout";
import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { PageLayout } from "../layouts/PageLayout";
import { ReportLayout } from "../layouts/ReportLayout/ReportLayout";
import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
import { Audit } from "../Pages/Audit/Audit";
import { DeviceList } from "../Pages/DeviceList/DeviceList";
import { EventList } from "../Pages/EventList/eventList";
import { GroupList } from "../Pages/GroupList/GroupList";
import { OnlineCampaign } from "../Pages/MessageCenter/Campaign/OnlineCampaign/onlineCampaign";
import { MessageList } from "../Pages/MessageCenter/MessageList/MessageList";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";
import { Overview } from "../Pages/Overview/Overview";
import { Reports } from "../Pages/ReportList/ReportList";
import { Apps } from "../Pages/Settings/Apps/apps";
import { GroupsUsers } from "../Pages/Settings/GroupsUsers/organizationUsers";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { SettingsOverview } from "../Pages/Settings/Overview/overview";
import { Users } from "../Pages/Settings/Users/organizationUsers";
import { VerifyRoute } from "./VerifyRoute";

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { manipulateReduxValues, accessToken, accessTokenIsValid } = useAuth();
  const { hasPrivileges, user } = useFlags();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();

  const indexRoute = useMemo(() => {
    if (hasPrivileges(["show_main_dashboard_page"])) return "/dashboard";

    if (hasPrivileges(["show_message_center_page"])) {
      return "/message-center/online-campaigns";
    }

    return "/settings/overview";
  }, [user]);

  useEffect(() => {
    manipulateReduxValues("SET_ACTION_ON_IDLE", handleOpenModalLogout);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, []);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          element={<VerifyRoute needAuth={true} element={<MainLayout />} />}
        >
          <Route
            path="network"
            element={
              <VerifyRoute
                needAuth={true}
                element={<EventListLayout />}
                onlyPrivileges
                userHasPrivileges={hasPrivileges(["show_network_event_page"])}
              />
            }
          >
            <Route
              path="event-list"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<EventList />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges(["show_network_event_page"])}
                />
              }
            />
          </Route>

          <Route
            path="settings"
            element={
              <VerifyRoute
                needAuth={true}
                element={<SettingsLayout />}
                onlyPrivileges
                userHasPrivileges={
                  hasPrivileges(["show_settings_overview_page"]) ||
                  hasPrivileges(["show_settings_my_account_page"]) ||
                  hasPrivileges(["show_settings_login_security_page"]) ||
                  hasPrivileges(["show_settings_users_page"]) ||
                  hasPrivileges(["show_settings_audit_page"]) ||
                  hasPrivileges(["show_settings_user_group_page"])
                }
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_overview_page",
                  ])}
                />
              }
            />
            <Route
              path="overview"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<SettingsOverview />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_overview_page",
                  ])}
                />
              }
            />

            <Route
              path="my-account"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<MyAccount />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_my_account_page",
                  ])}
                />
              }
            />

            <Route
              path="login-security"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<LoginSecurity />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_login_security_page",
                  ])}
                />
              }
            />

            <Route
              path="users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Users />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_users_page",
                  ])}
                />
              }
            />
            <Route
              path="groups-users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<GroupsUsers />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_user_group_page",
                  ])}
                />
              }
            />

            <Route
              path="audit"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Audit />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_settings_audit_page",
                  ])}
                />
              }
            />
          </Route>

          <Route
            path="dashboard"
            element={
              <VerifyRoute
                needAuth={true}
                element={<DashboardLayout />}
                onlyPrivileges
                userHasPrivileges={hasPrivileges(["show_main_dashboard_page"])}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Overview />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_main_dashboard_page",
                  ])}
                />
              }
            />
          </Route>

          <Route
            path="developers"
            element={
              <VerifyRoute
                needAuth={true}
                element={<DevelopersLayout />}
                onlyPrivileges
                userHasPrivileges={hasPrivileges(["show_developer_apps_page"])}
              />
            }
          >
            <Route
              index
              path="apps"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Apps />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_developer_apps_page",
                  ])}
                />
              }
            />
          </Route>

          <Route
            path="message-center"
            element={
              <VerifyRoute
                needAuth={true}
                element={<MessageCenterLayout />}
                onlyPrivileges
                userHasPrivileges={
                  hasPrivileges(["show_message_center_page"]) ||
                  hasPrivileges(["show_message_notification_list_page"])
                }
              />
            }
          >
            <Route
              path="online-campaigns"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<OnlineCampaign />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "show_message_center_page",
                  ])}
                />
              }
            />

            <Route
              path="message-list"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<MessageList />}
                  userHasPrivileges={hasPrivileges([
                    "show_message_notification_list_page",
                  ])}
                />
              }
            />
          </Route>

          <Route
            path="reports"
            element={
              <VerifyRoute
                needAuth={true}
                element={<ReportLayout />}
                onlyPrivileges
                userHasPrivileges={hasPrivileges(["show_main_report_page"])}
              />
            }
          >
            <Route
              index
              element={<VerifyRoute needAuth={true} element={<Reports />} />}
            />
          </Route>

          <Route
            path="devices"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                onlyPrivileges
                userHasPrivileges={
                  hasPrivileges(["show_devices_list_page"]) ||
                  hasPrivileges(["show_devices_group_page"])
                }
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<DeviceList />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges(["show_devices_list_page"])}
                />
              }
            />

            <Route
              path="groups"
              element={
                <VerifyRoute
                  needAuth={true}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges(["show_devices_group_page"])}
                  element={<GroupList />}
                />
              }
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                element={<Navigate to={indexRoute} />}
              />
            }
          />
        </Route>

        {accessToken && (
          <>
            <Route element={<OnboardLayout />}>
              <Route path="/join-organization" element={<SignUp />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route
              path="*"
              element={
                <GLPageNotFound
                  logoOptions={{
                    src: {
                      logoLight: GLLogo,
                      logoDark: GLLogoDark,
                    },
                    backgroundImage: RightLogo404,
                    alt: "GroupLink logo",
                    logoRight: Logo404,
                  }}
                  onClickButton={() => navigate("/")}
                  title="Oops!"
                  subtitle="Page not found"
                  description="It looks like you were detached from our IoT world..."
                />
              }
            />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};
