import { useI18n } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "usehooks-ts";

import { useOrganizationsService } from "../../../../Services/organizationsService/useOrganizations";
import { useImpersonateContentStore } from "../../store/impersonateContent.store";
import { useImpersonateStore } from "../../store/impersonateModal.store";

export const useOrganizationContent = () => {
  const { getOrganizations } = useOrganizationsService();
  const { state } = useImpersonateStore();

  const { t } = useI18n();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  const { data, isLoading } = useQuery({
    queryKey: [
      "get-organizations",
      state.modalSteperContentIsOpen,
      impersonateContentState.textToSearchOrg,
    ],
    queryFn: async () => {
      if (!state.modalSteperContentIsOpen) return;

      const response = await getOrganizations({
        use_case: "sr",
        org_name: impersonateContentState.textToSearchOrg,
      });

      return response.data.rows;
    },
  });

  const onSelectOrganization = async (orgId: string | number) => {
    if (orgId === impersonateContentState.organizationId) {
      impersonateContentActions.setOrganizationId(0);
      return;
    }
    impersonateContentActions.setOrganizationId(orgId);
  };

  return {
    data,
    isLoading,
    onSelectOrganization,
    impersonateContentState,
    impersonateContentActions,
    isMobile,
    t,
  };
};
