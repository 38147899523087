import { useAuth } from "@group-link-one/gl-auth";
import { GLHeader } from "@group-link-one/grouplink-components";
import { Outlet } from "react-router-dom";

import { useAuthServices } from "../../Services/authService/useAuthServices";
import { useMenuOptions } from "../useMenuOptions";
import { useMessageCenterLayout } from "./useMessageCenterLayout";

export function MessageCenterLayout() {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const { headerNavList, headerNavItemActive } = useMessageCenterLayout();
  const { menuOptions } = useMenuOptions();

  return (
    <>
      <GLHeader
        headerNavListItems={headerNavList}
        headerNavItemActive={headerNavItemActive?.id}
        onLogOut={() => logOut({})}
        user={user!}
        menuOptions={menuOptions}
      />
      <Outlet />
    </>
  );
}
