import {
  ICodeParams,
  ILoginSecurityData,
} from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useLoginSecurityServices = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const sendCode = async (data: ICodeParams) => {
    return await httpPrivate.post("/send-code", data);
  };

  const updatePasswordLogged = async (body: ILoginSecurityData) => {
    return await httpPrivate.patch("/reset-password-logged", body, {
      method: "PATCH",
    });
  };

  const getUserHistory = async () => {
    return await httpPrivate.get("/my-account/sessions?ipp=3");
  };

  const logoutSession = async (session_id: string) => {
    return await httpPrivate.post(
      "/logout",
      {},
      {
        params: {
          session_id,
        },
      }
    );
  };

  return {
    sendCode,
    updatePasswordLogged,
    getUserHistory,
    logoutSession,
  };
};
