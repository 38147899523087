import {
  GL_CALENDAR,
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GLDatePickerRange,
  GLDatePickerSingleCalendar,
  GLDropdown,
  GLRangeSlider,
  GLRecurringNotification,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "usehooks-ts";

import { useScheduleStore } from "../store/schedule";
import { ScheduleContainer } from "./ScheduleStyle";
import { useSchedule } from "./useSchedule";

export function Schedule() {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const { t } = useI18n();
  const {
    selectStartDate,
    selectSpecificDays,
    selectEndDate,
    inputFrequencyAndPeriodValue,
    onClickInStartNow,
    onClickCancel,
    onClickDone,
    endDateValueFormatted,
    startDateValueFormatted,
    actions,
    campaign_interval,
  } = useSchedule();

  const {
    state: { startDate, minimumInterval, endDate },
    actions: { setMinimumInterval },
  } = useScheduleStore();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <ScheduleContainer>
      <div className="schedule-block">
        <GLTypography
          fontSize={4}
          weight={600}
          color="DISABLED_FONT_COLOR"
          text={t(
            "messageCenter.list.modal.creationModal.steps.three.labels.scheduleSend"
          )}
          style={{ textTransform: "uppercase" }}
        />

        <GLDropdown.Root>
          <GLDropdown.Label
            text={t(
              "messageCenter.list.modal.creationModal.steps.three.labels.frequencyPeriod"
            )}
          />

          <GLDropdown.Input
            value={inputFrequencyAndPeriodValue}
            width="min(90vw, 400px)"
            icon={
              <GL_CHEVRON_DOWN
                size={12}
                fill={GL_COLORS.FONT_COLOR_DARK_GREY}
              />
            }
          />

          <GLDropdown.BoxOptions>
            <GLDropdown.Group
              groupTitle={t(
                "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.one"
              )}
            >
              <GLDropdown.Option
                text={t(
                  "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.startNow"
                )}
                hasCheckInActive={false}
                value={t(
                  "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.three"
                )}
                hasToShowSelected={false}
                onClick={() => onClickInStartNow()}
              />
              <GLDropdown.Option
                text={t("messageCenter.list.card.scheduled_in.specific_days")}
                hasCheckInActive={false}
                hasToShowSelected={false}
                contentPosition={isMobile ? "bottom" : "right"}
                content={
                  <GLDatePickerRange
                    hasActions
                    mode="multiple"
                    selected={selectedDates}
                    onSelect={(dates: Date[]) => setSelectedDates(dates || [])}
                    onResetDate={() => {
                      actions.setScheduleSendValueDate("");
                    }}
                    onApplyDate={() => selectSpecificDays(selectedDates)}
                    fromDate={new Date()}
                  />
                }
              />
            </GLDropdown.Group>

            <GLDropdown.Group
              groupTitle={t(
                "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.two"
              )}
            >
              <GLDropdown.Option
                text={t(
                  "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.customize"
                )}
                hasCheckInActive={false}
                hasToShowSelected={false}
                contentPosition={isMobile ? "bottom" : "right"}
                content={
                  <GLRecurringNotification
                    onActionDone={(options) => onClickDone(options)}
                    onActionCancel={onClickCancel}
                  />
                }
              />
            </GLDropdown.Group>
          </GLDropdown.BoxOptions>
        </GLDropdown.Root>
      </div>

      {inputFrequencyAndPeriodValue ===
        t(
          "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.six"
        ) || campaign_interval?.type === "specifics_days" ? (
        <></>
      ) : (
        <>
          <div>
            <GLDropdown.Root>
              <GLDropdown.Label
                text={t(
                  "messageCenter.list.modal.creationModal.steps.three.labels.startDate"
                )}
              />

              <GLDropdown.Input
                value={
                  startDateValueFormatted
                    ? startDateValueFormatted
                    : t("messageCenter.notDefinedYet")
                }
                width="min(90vw, 400px)"
                icon={
                  <GL_CALENDAR
                    size={12}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                }
              />

              <GLDropdown.BoxOptions width="fit-content">
                <GLDatePickerSingleCalendar
                  selected={startDate}
                  onSelect={selectStartDate}
                  fromDate={new Date()}
                />
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          </div>
          <div>
            <GLDropdown.Root>
              <GLDropdown.Label
                text={t(
                  "messageCenter.list.modal.creationModal.steps.three.labels.endDate"
                )}
              />

              <GLDropdown.Input
                value={
                  endDateValueFormatted
                    ? endDateValueFormatted
                    : t("messageCenter.notDefinedYet")
                }
                width="min(90vw, 400px)"
                icon={
                  <GL_CALENDAR
                    size={12}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                }
              />

              <GLDropdown.BoxOptions width="fit-content">
                <GLDatePickerSingleCalendar
                  selected={endDate}
                  onSelect={selectEndDate}
                  fromDate={new Date()}
                  onResetDate={() => {
                    actions.setEndDateFormatted("");
                    actions.setEndDate("");
                  }}
                />
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          </div>
        </>
      )}

      <div className="schedule-block">
        <GLTypography
          fontSize={4}
          weight={600}
          color="DISABLED_FONT_COLOR"
          text={t(
            "messageCenter.list.modal.creationModal.steps.three.labels.configureSend"
          )}
          style={{ textTransform: "uppercase" }}
        />

        <div className="schedule-range-radio">
          <GLTypography
            fontSize={4}
            weight={600}
            text={t(
              "messageCenter.list.modal.creationModal.steps.three.labels.minimumInterval"
            )}
            color="FONT_COLOR"
          />

          <GLRangeSlider
            max={24}
            min={1}
            step={2}
            type="hours"
            value={minimumInterval}
            onValueChange={(e) => {
              setMinimumInterval(e.value);
            }}
          />
        </div>

        {/* <div className="schedule-range-radio">
          <GLTypography
            fontSize={4}
            weight={600}
            text={t(
              "messageCenter.list.modal.creationModal.steps.three.labels.maximumInterval"
            )}
            color="FONT_COLOR"
          />

          <GLRangeSlider
            max={6}
            min={1}
            step={1}
            type="number"
            value={maximumTimes}
            onValueChange={(e) => {
              setMaximumTimes(e.value);
            }}
          />
        </div> */}
      </div>

      <Helmet title="Message Center - Schedule" />
    </ScheduleContainer>
  );
}
