import { create } from "zustand";

interface ImpersonateContentState {
  organizationId: string | number;
  textToSearchOrg: string;
  orgErrorMessage: string;

  userId: string | number;
  userErrorMessage: string;

  rootToken: string;
}

interface ImpersonateContentActions {
  setOrganizationId: (organizationId: string | number) => void;
  setOrganizationTextToSearch: (text: string) => void;
  setOrgErrorMessage: (errorMessage: string) => void;

  setUserId: (userId: string | number) => void;
  setUserErrorMessage: (errorMessage: string) => void;

  setRootToken: (rootToken: string) => void;

  resetAll: () => void;
}

interface ImpersonateStore {
  state: ImpersonateContentState;
  actions: ImpersonateContentActions;
}

export const useImpersonateContentStore = create<ImpersonateStore>((set) => ({
  state: {
    organizationId: 0,
    textToSearchOrg: "",
    orgErrorMessage: "",

    userId: "",
    userErrorMessage: "",
    textToSearchUser: "",

    rootToken: "",
  },
  actions: {
    setOrganizationId: (organizationId: string | number) => {
      set((state) => ({
        state: {
          ...state.state,
          organizationId: organizationId,
        },
      }));
    },
    setOrganizationTextToSearch: (text: string) => {
      set((state) => ({
        state: {
          ...state.state,
          textToSearchOrg: text,
        },
      }));
    },

    setUserId: (userId: string | number) => {
      set((state) => ({
        state: {
          ...state.state,
          userId: userId,
        },
      }));
    },

    setRootToken: (rootToken: string) => {
      set((state) => ({
        state: {
          ...state.state,
          rootToken: rootToken,
        },
      }));
    },

    setOrgErrorMessage: (errorMessage: string) => {
      set((state) => ({
        state: {
          ...state.state,
          orgErrorMessage: errorMessage,
        },
      }));
    },

    setUserErrorMessage: (errorMessage: string) => {
      set((state) => ({
        state: {
          ...state.state,
          userErrorMessage: errorMessage,
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          organizationId: 0,
          textToSearchOrg: "",

          userId: 0,
        },
      }));
    },
  },
}));
